<template>
	<div id="support" class="pb-1 mb-0">
		<v-row class="pa-0 ma-0" align="center" no-gutters>
			<v-col cols="12" class="px-0 primary bg-wavelines-secondary-dark white--text">
				<div class="mx-auto mb-5 pb-5" id="how" style="max-width: 1200px;">
					<h2 class="display-1 mt-md-5 pt-10 mb-1 text-center text-md-h3 shadowed">Support</h2>
					<p class="title mb-0 lh-14 text-center mx-auto mb-3 mb-md-5 shadowed" style="max-width: 600px;">
						Help Us Help You
					</p>
				</div>
			</v-col>
		</v-row>
		<v-row class="white mt-5">
			<v-col cols="12">
				<div class="mx-auto mb-5 pb-5 px-5 px-md-1 secondary--text" id="how" style="max-width: 1000px;">
					<v-row>
						<v-col cols="12">
							<h1 class="text-h5 text-center text-md-left">
								Common Questions
								<small class="hidden-sm-and-down float-right primary--text body-2" @click="$vuetify.goTo('#contact', { container: 'body', offset: 60 })">
									<v-icon color="primary" class="mr-1 action-item">fa fa-envelope</v-icon> <span class="action-item contact-us-lg">Contact Us</span>
								</small>
							</h1>
							<small class="hidden-md-and-up text-center d-block d-md-none primary--text" @click="$vuetify.goTo('#contact', { container: 'body' })">
								<v-icon small color="primary">fa fa-envelope</v-icon> <span class="action-item contact-us-sm">Contact Us</span>
							</small>
							<v-divider class="mt-3 mt-md-1" />
						</v-col>
						<v-col cols="12" v-show="loading" class="subtitle-1">
							<v-progress-circular indeterminate color="primary"></v-progress-circular> Loading...
						</v-col>
						<template v-for="(item, i) in faqs">
							<v-col cols="12" :key="item.id" class="pb-3 pt-0">
								<div class="faq-container">
									<h2 class="title text-md-h6 mb-0">{{ item.name }}</h2>
									<template v-for="article in item.articles">
										<div class="article" :key="article.id" :id="'article-' + article.id">
											<div class="d-flex">
												<div class="align-self-start">
													<v-icon color="primary" x-small class="mr-2 mt-1 hidden-md-and-up">fas fa-chevron-right</v-icon>
													<v-icon color="primary" small class="mr-2 mt-1 hidden-sm-and-down">fas fa-chevron-right</v-icon>
												</div>
												<div class="align-self-start pt-1">
													<h3 @click="showArticle('article-' + article.id, article.title)" class="q subtitle-1 lh-12 text-md-subtitle-1 font-weight-regular"><span class="action-item"> {{ article.title }}</span></h3>
													<div class="a lh-12" :id="article.id" v-html="article.description" />
												</div>
											</div>
										</div>
									</template>
									<v-divider v-if="i < faqs.length - 1" class="mt-3 mt-md-3" />
								</div>
							</v-col>
						</template>
					</v-row>
				</div>
			</v-col>
		</v-row>
		<v-row dense id="contact">
			<v-col cols="12">
				<div class="mx-auto px-5 px-md-1 secondary--text" id="how" style="max-width: 1000px;">
					<h1 class="text-h5 text-center text-md-left mb-0 mb-md-0">Contact Us</h1>
					<v-divider class="mt-3 mt-md-3 mb-1" />
				</div>
			</v-col>
			<v-col cols="12" v-if="sent">
				<div class="mx-auto mb-5 pb-5 px-5 px-md-1 secondary--text" id="how" style="max-width: 1000px;">
					<v-alert type="success" class="font-weight-medium">Your ticket has been created. We look forward to helping you soon!</v-alert>
				</div>
			</v-col>
			<v-col cols="12" v-if="errors">
				<div class="mx-auto pb-5 px-5 px-md-1 secondary--text" id="how" style="max-width: 1000px;">
					<v-alert type="error" class="font-weight-medium my-0">{{ errors }}</v-alert>
				</div>
			</v-col>
			<v-col cols="12" v-if="!sent">
				<div class="mx-auto mb-5 pb-5 px-5 px-md-1 secondary--text" id="how" style="max-width: 1000px;">
					<v-row dense>
						<v-col cols="12" md="12">
							<p class="subtitle-1 mt-0 mb-4 lh-14 mt-md-0">Have more questions? Submit the form below to open a support ticket and one of our staff will be happy to assist you as soon as possible!</p>
						</v-col>
						<v-col cols="12" md="6">
							<v-text-field dense label="Your Name" :rules="[rules.required, rules.minlength]" v-model="contact.name" placeholder="Ex: Ruby Kate" outlined required />
						</v-col>
						<v-col cols="12" md="6">
							<v-text-field dense label="Email Address" :rules="[rules.required, rules.email]" v-model="contact.email" placeholder="Ex: ruby.kate@gmail.com" type="email" outlined required />
						</v-col>
						<v-col cols="12">
							<v-text-field dense label="Subject" :rules="[rules.required, rules.minlength]" v-model="contact.subject" placeholder="Ex: How do I..." outlined required />
						</v-col>
						<v-col cols="12">
							<v-textarea dense label="Your Message" :rules="[rules.required, rules.minlength]" v-model="contact.description" outlined placeholder="Ex: I would like to know more about..." />
						</v-col>
						<v-col cols="12" class="text-center">
							<v-btn color="primary" :loading="sending" large :disabled="!canSubmit()" @click.native="submit()" rounded class="px-14">Submit</v-btn>
						</v-col>
					</v-row>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>

import { mapState } from 'vuex'
import faqs from '@/data/faqs'

export default {
	name: 'Support',
	computed: {
        ...mapState(['user']),
    },
	data: () => ({
		loading: true,
		loadingHubSpot: true,
		errors: null,
		faqs: faqs.faqs,
		contact: {
			action: 'contact',
			name: '',
			email: '',
			subject: '',
			description: ''
		},
		rules: {
            required: value => !!value || 'Required.',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Invalid e-mail.'
            },
            minlength: value => value && value.length > 5 || 'Must be at least 6 characters.',
        },
		sending: false,
		sent: false,
	}),
	components: {
	},
	methods: {
		initData(){
			if(this.user && this.user.email){
				this.contact.email = this.user.email;
			}
			if(this.user && this.user.name){
				this.contact.name = this.user.name;
			}
			this.loadHubspot();
			// this.getCategories();
			this.loading = false;
		},
		loadHubspot(){
			let script = document.createElement('script');
			script.type = 'text/javascript';
			script.async = true;
			script.onload = () => {
				this.loadingHubSpot = false;
				// let formCode = 
			};
			script.src = 'https://js.hsforms.net/forms/shell.js';
			document.getElementsByTagName('head')[0].appendChild(script);
		},
		canSubmit(){
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(this.contact.email) && this.contact.name.length > 5 && this.contact.subject.length > 5 && this.contact.description.length > 5;
        },
		showArticle(article, title){
			let faqItem = document.getElementById(article);
			if(!faqItem.classList.contains('active')){
				this.$appFn.trackEvent({ action: 'faq_view', category: 'FAQ_Views', label: title, options: {
					faq_title: title
				}});
			}
			faqItem.classList.toggle('active');
		},
		getCategories(){
            let request = {
                endpoint: '/support/api', 
                method: 'post',
                data: {
                    'action' : 'categories'
                },
            };
            this.$appFn.callApi(request).then((res)=>{
                this.faqs = res.data;
            }).catch(()=>{
            }).finally(()=>{
                this.loading = false;
            });
        },
		submit(){ 
            this.sending = true;
            this.errors = null;
            let request = {
                endpoint: '/support/api', 
                method: 'post',
                data: this.contact
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
					this.sent = true;
					this.$appFn.trackEvent({ action: 'search', label: 'Contact form submitted.'});
                }else{
					this.$appFn.trackEvent({ action: 'user_error', category: 'UserErrors', action_name: 'Could not submit contact form.'});
                    this.errors = "We had trouble submitting your ticket. Please try again.";
                }
            }).catch(()=>{
                this.errors = "We had trouble submitting your ticket. Please try again.";
                this.$appFn.trackEvent({ action: 'user_error', category: 'UserErrors', action_name: 'Could not submit contact form.'});
            }).finally(()=>{
                this.sending = false;
            });
        }
	},
	beforeDestroy(){
	},
	mounted(){
		this.initData();
	},
	watch: {
		
	}
}
</script>
